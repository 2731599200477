import user from '@/store/user';
import { getModule } from "vuex-module-decorators";
import job from '@/store/job';
import history from '@/store/history';
import search from '@/store/search';
import queue from '@/store/queue';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class StoreMixin extends Vue {

    protected get $userStore(): user {
        return getModule(user, this.$store);
    }

    protected get $jobStore(): job {
        return getModule(job, this.$store);
    }

    protected get $queueStore(): queue {
        return getModule(queue, this.$store);
    }

    protected get $historyStore(): history {
        return getModule(history, this.$store);
    }
    protected get $searchStore(): search {
        return getModule(search, this.$store);
    }

}