import Vue from 'vue';
import orderBy from 'lodash/orderBy';
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import { buildSearchHash, searchQueryBuilder } from '@/utils/helpers/searching';
import { FacetOptionResult, IFacetResult, ISearchQuery, SearchFacet, SearchFacetType, SearchSorting } from '@/utils/api/SchedulerApiClient';
import consts from '@/utils/consts';
import { CancelTokenSource} from 'axios';
import axios from 'axios';
import Apis from '@/utils/api/Apis';


// Hvad kan ændre sig?
// * initialize ved skift af side (facets, page size, etc. )
// * Pagination
// * Sorting
// * Filtering
// * Search query


export interface ISortingOption {
    name: string;
    queryValue: string;
    sortings: SearchSorting[];
}

export interface ISearchContainer {
    configuration: ISearchContainerConfiguration;
    currentPage: number;
    totalPages: number;
    items: ISearchResultItem[];
    totalItems: number;
    facetOptions: {[field: string]: IFacetResult };
    facetSelected: {[field: string]: ISearchFacetSelected };
    sortingSelected?: ISortingOption;
    isSearching: boolean;
    freeTextSearch: string;
    
    // For avoiding double searrch
    configHash?: number;
    searchHash?: number;

}



export interface ISearchContainerConfiguration {
    pageSize: number;
    search: ISearchQuery;
    facets: ISearchFacet[];
    textSearchFields?: string[];
    sortingOptions: ISortingOption[];
    endpoint: string;
}

export interface ISearchFacet {
    type: SearchFacetType;
    name: string;
    field: string;
    queryField: string;
}

// Options
export interface ISearchFacetOption {
    type: string;
}
export interface ISearchFacetOptionCheckbox extends ISearchFacetOption {
    type: 'checkbox';
    options: ISearchFacetOptionCheckboxOption[];
}
export interface ISearchFacetOptionCheckboxOption
{
    key: string;
    count: number;
    selected: boolean;
}

// Options selected
export interface ISearchFacetSelected
{
    type: string;
}
export interface ISearchFacetSelectedCheckbox extends ISearchFacetSelected {
    type: 'checkbox';
    selected: {[key: string]: boolean};
}



export interface ISearchResultItem 
{
    score: number;
    item: any;
}


@Module({
    name: 'search',
    stateFactory: true,
    namespaced: true,
})
export default class SearchModule extends VuexModule {
    public containers: {[ref: string]: ISearchContainer} = {};


    // GETTERS =======================================
    public get isSearching(): (ref: string) => boolean {
        return (ref: string) => {
            return this.containers[ref]?.isSearching || false;
        };
    }


    public get items(): (ref: string) => ISearchResultItem[] {
        return (ref: string) => {
            const container = this.containers[ref];
            if(container) {
                return container.items;
            }
            return [];
        };
    }

    public get totalItems(): (ref: string) => number {
        return (ref: string) => {
            const container = this.containers[ref];
            if(container) {
                return container.totalItems;
            }
            return 0;
        };
    }

    public get totalPages(): (ref: string) => number {
        return (ref: string) => {
            const container = this.containers[ref];
            if(container && container.totalPages > 0) {
                return container.totalPages;
            }
            return 1;
        };
    }
    public get facets(): (ref: string) => { facet: ISearchFacet, options: ISearchFacetOption}[] {
        return (ref: string) => {
            const container = this.containers[ref];
            if(!container) {
                return [];
            }
            return container.configuration.facets.map(facet => {
                switch(facet.type) {
                    case SearchFacetType.CheckboxAnd:
                    case SearchFacetType.CheckboxOr:
                        const optionResult = <FacetOptionResult>container.facetOptions[facet.field];
                        if(!optionResult) {
                            return <{ facet: ISearchFacet, options: ISearchFacetOption}>{ facet: facet, options: <ISearchFacetOption><any><FacetOptionResult>{items: []}};
                        }

                        return <{ facet: ISearchFacet, options: ISearchFacetOption}>{
                            facet: facet,
                            options: <ISearchFacetOption><any><ISearchFacetOptionCheckbox>{
                                type: 'checkbox',
                                options: optionResult.items?.map(option => {
                                    const facetSelectedGroup = <ISearchFacetSelectedCheckbox>container.facetSelected[facet.field];
                                    return <ISearchFacetOptionCheckboxOption>{
                                            key: option.key,
                                            count: option.count || 0,
                                            selected: (option.key && facetSelectedGroup && facetSelectedGroup.selected[option.key]) ? true : false
                                    };
                                }) || []
                            }
                        }
                    default:
                        throw 'Type not implemented'
                }
            });
            
        
        };
    }

    public get selectedFacets(): (ref: string) => { facet: ISearchFacet, options: ISearchFacetOption[]}[] {
        return (ref: string) => {
            const container = this.containers[ref];
            if(!container) {
                return [];
            }
            return [];

            // const result: { facet: ISearchFacet, options: ISearchFacetOption[]}[] = [];
            // for(const facet of container.configuration.facets) {
            //     const options = container.facetOptions[facet.field];
            //     if(!options) continue;

            //     let resultItem: { facet: ISearchFacet, options: ISearchFacetOption[]} | undefined = undefined;

            //     for(const option of options) {
            //         const selected = (option.key && container.facetSelected[facet.field] 
            //             && container.facetSelected[facet.field][option.key]) ? true : false;

            //         if(selected && option.key) {
            //             if(!resultItem) {
            //                 resultItem = {
            //                     facet: facet,
            //                     options: []
            //                 };
            //                 result.push(resultItem);
            //             }
            //             resultItem.options.push({
            //                 key: option.key,
            //                 count: option.count || 0,
            //                 selected: true
            //             });
            //         }
            //     }
            // }
            // return result;
        };
    }

    public get currentPageNumber(): (ref: string) => number {
        return (ref: string) => {
            const container = this.containers[ref];
            if(container && container.currentPage > 0) {
                return container.currentPage;
            }
            return 1;
        };
    }

    public get queryObject(): (ref: string) => any {
        return (ref: string) => {
            const query: any = {};
            const container = this.containers[ref];
            if(container) {
                if(container.freeTextSearch && typeof container.freeTextSearch === 'string') {
                    query.q = container.freeTextSearch;
                }
                if(container.currentPage > 1) {
                    query[consts.paginationQueryName] = container.currentPage;
                }

                for(const facet of container.configuration.facets) {
                    const options = container.facetSelected[facet.field];
                    if(!options) continue;
                    const values: any[] = [];
                    for(const key in options) {
                        if(options[key]) {
                            values.push(key);
                        }
                    }
                    if(values.length > 0) {
                        query[facet.queryField] = values.join(consts.facetQueryValueSeparator);
                    }
                }
                var sortOptions = this.sortingOptions(ref);
                if(sortOptions.length > 0 && container.sortingSelected && sortOptions[0].queryValue !== container.sortingSelected.queryValue) {
                     query.s = container.sortingSelected.queryValue;
                }
            }
            return query;
        }
    }

    public get sortingOptions(): (ref: string) => ISortingOption[] {
        return (ref: string) => {
            const container = this.containers[ref];
            return container?.configuration?.sortingOptions 
                ? container.configuration.sortingOptions.filter(f => container.freeTextSearch || f.sortings.filter(s => s.field === '_score').length === 0)
                : [];
        };
    }

    public get selectedSortingOption(): (ref: string) => ISortingOption | undefined {
        return (ref: string) => {
            return this.containers[ref]?.sortingSelected || (this.sortingOptions(ref).length > 0 ? this.sortingOptions(ref)[0] : undefined);
        };
    }

    // MUTATIONS =====================================
    @Mutation
    public setConfigurationCommit(args: { ref: string, configuration: ISearchContainerConfiguration }) {
        // let currentPage = parseInt(args.query[consts.paginationQueryName]);
        // if(currentPage < 1 || isNaN(currentPage)) {
        //     currentPage = 1;
        // }
        let currentPage = 1;
        const facetSelected: {[field: string]: ISearchFacetSelected} = {};
        // for(let facet of args.configuration.facets) {
        //     const queryValues = args.query[facet.queryField];
        //     if(queryValues) {
        //         facetSelected[facet.field] = {};
        //         const values = queryValues.split(consts.facetQueryValueSeparator);
        //         for(const value of values) {
        //             facetSelected[facet.field][value] = true;
        //         }
        //     }
        // }
        let sortingSelected: ISortingOption | undefined;
        // for(let sortOption of args.configuration.sortingOptions) {
        //     if(args.query.s && args.query.s === sortOption.queryValue) {
        //         sortingSelected = sortOption;
        //         break;
        //     }
        // }
        let newContainer: ISearchContainer = {
            configuration: args.configuration,
            currentPage,
            facetOptions: {},
            facetSelected,
            freeTextSearch: '',
            isSearching: false,
            items: [],
            totalItems: 0,
            totalPages: 0,
            sortingSelected,
        };
        newContainer.configHash = buildSearchHash(newContainer);

        let container = this.containers[args.ref];
        if(!container) {
            container = newContainer;
        } else {
            if(container.configHash === newContainer.configHash) {
                // console.log(`skipping config (hash equals: old: ${container.configHash}, new: ${newContainer.configHash})`, {container, newContainer});
                return;
            } else {
                // console.log(`updating config (hash equals: old: ${container.configHash}, new: ${newContainer.configHash})`, {container, newContainer});
            }
            container = newContainer;
        }
        

        // object spread forces front-end update :)
        Vue.set(this.containers, args.ref, {...container});
    }
    @Mutation
    public setSearching(args: {ref: string}) {
        const container = this.containers[args.ref];
        if(!container) throw 'missing configuration for search';
        container.isSearching = true;
        container.searchHash = container.configHash;

        // object spread forces front-end update :)
        Vue.set(this.containers, args.ref, {...container});
    }

    @Mutation
    public searchResultCommit(args: {ref: string, response: {itemsTotal: number, items:ISearchResultItem[], facetOptions: { [key: string]: IFacetResult; } } }) {
        const container = this.containers[args.ref];
        if(!container) throw 'missing configuration for search';
        const totalItems = args.response.itemsTotal || 0;
        container.totalPages = Math.floor(totalItems / container.configuration.pageSize) + 1;
        container.totalItems = totalItems;
        container.items = args.response.items?.map(i => <ISearchResultItem>{item: i.item, score: i.score }) || [];
        // container.facetOptions = {};
        container.isSearching = false;
        container.facetOptions = args.response.facetOptions || [];

        // object spread forces front-end update :)
        Vue.set(this.containers, args.ref, {...container});
    }

    @Mutation
    public setPageCommit(args: {ref: string, currentPage: number }) {
        const container = this.containers[args.ref];
        
        if(!container) throw 'setPageCommit: missing configuration for search';
        container.currentPage = args.currentPage;
        container.configHash = buildSearchHash(container);

        // object spread forces front-end update :)
        Vue.set(this.containers, args.ref, {...container});
     }

     @Mutation
     public setFacetSelectedCommit(args: {ref: string, field: string, key: string, selected: boolean}) {
        const container = this.containers[args.ref];
        const field = container.configuration.facets?.find(f => f.field == args.field);
        if(!container || !field) throw 'setFacetSelectedCommit: missing configuration for search';

        switch(field.type) {
            case SearchFacetType.CheckboxAnd:
            case SearchFacetType.CheckboxOr:
                let checkboxOptions = <ISearchFacetSelectedCheckbox>container.facetSelected[args.field];
                if(!checkboxOptions) {
                    container.facetSelected[args.field] = checkboxOptions = {
                        type: 'checkbox',
                        selected: {}
                    };
                }
                checkboxOptions.selected[args.key] = args.selected;
                console.log({args, checkboxOptions});
                break;
            default:
                throw 'Filter not implemented';
        }



        // if(!options) {
        //     container.facetSelected[args.field] = options = {};
        // }
        // options[args.key] = args.selected;
        container.currentPage = 1;
        container.configHash = buildSearchHash(container);


        // object spread forces front-end update :)
        Vue.set(this.containers, args.ref, {...container});
     }

     @Mutation
     public clearFacetSelectedCommit(args: {ref: string}) {
        const container = this.containers[args.ref];
        if(!container) throw 'missing configuration for search';
         for(const field in container.facetSelected) {
            for(const key in container.facetSelected[field]) {
                container.facetSelected[field][key] = false;
            }
         }
         container.currentPage = 1;
         container.configHash = buildSearchHash(container);

        // object spread forces front-end update :)
        Vue.set(this.containers, args.ref, {...container});
    }

    @Mutation
    public setFreeText(args: { ref: string, text: string }) {
        const container = this.containers[args.ref];
        if(!container) throw `setFreeText(${args.ref}): missing configuration for search`;
        container.freeTextSearch = args.text;
        container.configHash = buildSearchHash(container);

        // object spread forces front-end update :)
        Vue.set(this.containers, args.ref, {...container});
    }

    @Mutation
    public setSortingCommit(args: {ref: string, sorting:ISortingOption}) {
        const container = this.containers[args.ref];
        if(!container) throw 'missing configuration for search';
        container.sortingSelected = args.sorting;
        container.configHash = buildSearchHash(container);

        // object spread forces front-end update :)
        Vue.set(this.containers, args.ref, {...container});
        
    }

    // ACTIONS =======================================

    @Action
    public setConfiguration(args: { ref: string, configuration: ISearchContainerConfiguration }) {
        this.setConfigurationCommit({configuration: args.configuration, ref: args.ref });
    }


    @Action({rawError: true})
    public async search(args: { ref: string, force?: boolean, ct?: CancelTokenSource }) { 
        const container = this.containers[args.ref];
        if(!container) throw 'missing configuration for search';
        const searchParams: {
            skip: number;
            take: number;
            sorting: SearchSorting[];
            search: ISearchQuery;
            facets: SearchFacet[];
        } = {
            facets: container.configuration.facets.map(f => <SearchFacet>{
                type: f.type,
                field: f.field
            }),
            skip: (container.currentPage - 1) * container.configuration.pageSize,
            take: container.configuration.pageSize,
            search: searchQueryBuilder(container),
            sorting: (container.sortingSelected?.sortings && container.sortingSelected?.sortings.length > 0 ?
                 container.sortingSelected.sortings : 
                 (container.configuration.sortingOptions.length > 0 ? container.configuration.sortingOptions[0].sortings : [])
            )
        };
        
        if(container.configHash !== container.searchHash || args.force) {
            // console.log(`Searching`);
            this.setSearching(args);
            
            if(!args.ct){
                args.ct = axios.CancelToken.source();
            }
            const response = await Apis.schedulerAxios.post(container.configuration.endpoint, searchParams, {cancelToken: args.ct.token});
            const jsonResponse = <{itemsTotal: number, items:ISearchResultItem[], facetOptions: { [key: string]: IFacetResult[]; } }>JSON.parse(response.data);
            this.searchResultCommit({
                ref: args.ref,
                response: jsonResponse
            });
        } else {
            // console.log(`skipping search (hash equals: config: ${container.configHash}, search: ${container.searchHash})`, container);
        }

    }

    @Action({rawError: true})
    public async setFacetSelected(args: {ref: string, field: string, key: string, selected: boolean}) {
        this.setFacetSelectedCommit(args);
    }

    @Action({rawError: true})
    public async setSorting(args: {ref: string, sorting:ISortingOption}) {
        this.setSortingCommit(args);
    }

    @Action({rawError: true})
    public async setPage(args: {ref: string, currentPage: number }) {
        this.setPageCommit(args);
    }

    @Action({rawError: true})
    public async clearFacetSelected(args: {ref: string}) {
        this.clearFacetSelectedCommit(args);
    }
}

