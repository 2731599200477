













import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  name: "NorriqPageRenderer",
})
export default class PageRenderer extends Vue {
  @Prop({ required: false, default: "page-content | p-4" })
  public pageClass: string;
}
