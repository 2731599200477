import Vue from 'vue'
import Vuex from 'vuex'
import user from './user';
import history from './history';
import job from './job';
import search from './search';
import queue from './queue';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    history,
    job,
    search,
    queue
  }
})
