






















import StoreMixin from "@/mixins/StoreMixin";
import { IJobDescription } from "@/utils/api/SchedulerApiClient";
import NorriqJobCard from "@/components/jobs/JobCard.vue";
import NorriqPageRenderer from "@/components/layout/PageRenderer.vue";
import { Component, Mixins } from "vue-property-decorator";
import NorriqHistoryTable from "@/components/history/HistoryTable.vue";
@Component({
  components: {
    NorriqJobCard,
    NorriqPageRenderer,
    NorriqHistoryTable,
  },
})
export default class Jobs extends Mixins(StoreMixin) {
  public loaded: boolean = false;

  public get jobs(): IJobDescription[] {
    return this.$jobStore.jobs;
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      await this.$jobStore.loadJobs();
      await this.$queueStore.loadQueues();
      await this.$historyStore.refreshAll();
      this.loaded = true;
    } catch {
      setTimeout(this.load, 2000); // Try again in 2000ms
    }
  }
}
