export default {
    defaultProductListRef: 'products',
    facetQueryValueSeparator: ',',
    paginationQueryName: 'p',
    defaultPageLookupTemplate: 'page',
    frontpageLookupTemplate: 'frontpage',
    defaultProductInCartLookupTemplate: 'product-cart',
    memberLookupTemplate: 'member',
    salesPersonsLookupTemplate: 'sales-persons',

    searchProductsEndpoint: '/api/Product/GetProductsBySearch'
};
