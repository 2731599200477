











import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { ISearchFacet, ISearchFacetOption, ISearchFacetOptionCheckbox, ISearchFacetOptionCheckboxOption } from "@/store/search";
import StoreMixin from "@/mixins/StoreMixin";
import { SearchFacetType } from "@/utils/api/SchedulerApiClient";
@Component({})
export default class FacetFilterSingle extends Mixins(StoreMixin) {

@Prop({ required: true })
    public searchRef: string;

    @Prop({ required: true })
    public facetName: string;

    public get options(): ISearchFacetOptionCheckboxOption[] {
        if(this.facet?.facet?.type == SearchFacetType.CheckboxAnd || this.facet?.facet?.type == SearchFacetType.CheckboxOr) {
            const facetOptions = <ISearchFacetOptionCheckbox>this.facet.options;
            return facetOptions?.options || [];
        }
        return [];
    }

    public get facets(): {
        facet: ISearchFacet;
        options: ISearchFacetOption;
    }[] {
        return this.$searchStore.facets(this.searchRef);
    }

    public get totalCount(): number {
        let count = 0;
        
        if(this.facet?.facet?.type == SearchFacetType.CheckboxAnd || this.facet?.facet?.type == SearchFacetType.CheckboxOr) {
            const facetOptions = <ISearchFacetOptionCheckbox>this.facet.options;
            if(facetOptions.options) {
                for(let option of facetOptions.options) {
                    count += option.count;
                }
            }
        }
        return count;
    }

    public get facet(): { facet: ISearchFacet; options: ISearchFacetOption; } | undefined {
        if(!this.facets) return undefined;
        const facet = this.facets.filter(f => f.facet.name == this.facetName);
        return facet.length > 0 ? facet[0] : undefined;
    }

    public get allSelected(): boolean {
        return !this.facet || !this.options.find(s => s.selected);
    }

    public async onSelectSingle(selectedOption: ISearchFacetOptionCheckboxOption | undefined) {
        if(!selectedOption || !this.facet?.facet.field) return;
        if(selectedOption?.selected) {
                this.$searchStore.setFacetSelected({
                    ref: this.searchRef,
                    field: this.facet?.facet.field,
                    key: selectedOption.key,
                    selected: false,
                });
        }
        if(selectedOption?.key) {
            this.$searchStore.setFacetSelected({
                ref: this.searchRef,
                field: this.facet.facet.field,
                key: selectedOption.key,
                selected: true,
            });
        }
        await this.$searchStore.search({
            ref: this.searchRef
        });
    }

    public get isSearching(): boolean {
        return this.$searchStore.isSearching(this.searchRef);
    }
}
