















import StoreMixin from "@/mixins/StoreMixin";
import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Pagination extends Mixins(StoreMixin) {
  @Prop({ required: true })
  public searchRef: string;

  public showWhileSearching: boolean = false;

  public get currentPage(): number {
    return this.$searchStore.currentPageNumber(this.searchRef);
  }

  public isSearching(): boolean {
    return this.$searchStore.isSearching(this.searchRef);
  }

  public async togglePrevPage() {
    await this.togglePage(this.currentPage - 1);
  }
  public async togglePage(page: number) {
    window.scrollTo(0, 0);
    if (page <= this.totalPages && page > 0) {
      await this.$searchStore.setPage({ ref: this.searchRef, currentPage: page });
      await this.$searchStore.search({ ref: this.searchRef });
    }
    this.showWhileSearching = false;
  }

  public async toggleNextPage() {
    await this.togglePage(this.currentPage + 1);
  }

  public get totalPages(): number {
    return this.$searchStore.totalPages(this.searchRef);
  }
}
