import axios from 'axios';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import { GetJobsRequest, GetJobsResponse, IJobDescription, IRunningJob } from '@/utils/api/SchedulerApiClient';
import Apis from '@/utils/api/Apis';
import Vue from 'vue';

@Module({ name: 'job', stateFactory: true, namespaced: true })
export default class Job extends VuexModule {
    private _jobs: IJobDescription[] = [];
    public get jobs() {
        return this._jobs;
    }
    @Mutation
    public loadJobsCommit(args: IJobDescription[]) {
        Vue.set(this, '_jobs', args);
    }

    @Action({rawError:true})
    public async loadJobs() {
        const jobs = await Apis.schedulerApi.getJobs(<GetJobsRequest>{});
        this.loadJobsCommit(jobs.jobs || []);

    }
}
