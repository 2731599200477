



































































import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import { IJobDescription, IRunningJob, ScheduledJob, QueueDescription } from "@/utils/api/SchedulerApiClient";
import StoreMixin from "@/mixins/StoreMixin";
import JobMixin, { IModeDetails, IQueueDetails } from "@/mixins/JobMixin";
import NorriqRunningJobIcon from "@/components/history/RunningJobIcon.vue";
import Apis from "@/utils/api/Apis";

@Component({
  components: {
    NorriqRunningJobIcon,
  },
})
export default class JobCardComponent extends Mixins(StoreMixin, JobMixin) {
  @Prop({ required: true })
  public job: IJobDescription;

  public get modes(): IModeDetails[] {
    if(!this.job) return [];

    // Only return modes that is either used previously or is scheduled
    return this.getModes(this.job).filter(mode => this.getCreated(mode.lastJob) || this.getNextDate(mode));
  }

  public get queues(): IQueueDetails[]
  {
    if(!this.job) return [];
    return this.getQueues(this.job);
  }

  public get newestJob(): IRunningJob | undefined {
    const list = this.modes.map((m) => m.lastJob).filter((l) => !!l);

    list.sort((l1, l2) => new Date(<string>(<any>l2?.created)).getTime() - new Date(<string>(<any>l1?.created)).getTime());
    if (list.length > 0) {
      return list[0];
    }
  }

  public async onStartJob(mode: IModeDetails) {
    const startJob = await Apis.schedulerApi.startJob({ jobConfigurationId: this.job.job.id, mode: mode.mode || "" });
    this.$router.push(`/history/${startJob.queuedJobId}`);
  }

  public getQueueName(queue: QueueDescription): string {
    const split = queue.messageType!.split('.');
    return split[split.length-1];
  }

  public getQueueTitle(queue: QueueDescription): string {
    return `source: ${queue.source}, type: ${queue.messageType}, queueNamePostfix: ${queue.queueNamePostfix}, exclusive: ${queue.exclusive}`;

  }

  public onClick(mode: IModeDetails) {
    if (mode.lastJob) {
      this.$router.push(`/history/${mode.lastJob.id}`);
    } else if (this.$route.fullPath != `/job/${this.job.job.id}`) {
      this.$router.push(`/job/${this.job.job.id}`);
    }
  }
}
